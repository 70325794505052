<template>
  <transition-group v-if="!isGetTemplateCollectionPending" name="milestone-list" class="list milestones-list" tag="ul">
    <li v-for="milestone of milestones" :key="milestone.id" class="list-item milestone-list-item">
      <div class="list">
        <div class="list list-item">
          <div></div>
          <div class="list-button" @click="toggleMilestone(milestone)">
            <hub-icon :name="!milestone.expanded ? 'chevron-down' : 'chevron-up'" size="md" />
          </div>
          <div title="Create task" class="list-button" @click="onCreateTask(milestone)">
            <hub-icon name="plus" size="md" />
          </div>
          <div class="milestone-list-item-title">
            <div class="title">{{ milestone.title }}</div>
            <div v-if="milestone.workflow?.id" class="buttons">
              <a
                v-if="$hasPermission('workflows') && milestone.workflow?.id && milestone.workflow?.templateId"
                :href="`https://backoffice.${host}/workflows/${milestone.workflow.id}/milestones/${milestone.workflow.templateId}`"
                target="_blank"
                class="edit-milestone list-button"
                title="Show template in editor"
              >
                <hub-icon name="open-in-new" size="md" />
              </a>
              <div class="edit-milestone list-button" type="icon" title="Edit mielstone" @click.stop="editMilestone(milestone)">
                <hub-icon name="pencil" size="md" />
              </div>
            </div>
          </div>
          <div class="milestone-list-item-dueAt">
            <hub-locale-due-at
              v-if="!milestone.completed && !milestone.deleted"
              :due-at="milestone.workflow.milestoneDueAt"
              :emtpy-due-at-text="'no due date'"
            />
            <div v-else-if="milestone.deleted"><label>deleted</label></div>
            <div v-else><label>completed</label></div>
          </div>
        </div>
      </div>
      <div v-if="milestone.expanded" class="task-list">
        <div class="notes-section">
          <milestone-notes :milestone-id="milestone.tasks[0]?.workflow?.milestoneId" />
        </div>
        <transition-group name="task-list" tag="ul" class="vertical-alignment">
          <li v-for="task of milestone.tasks" :key="task.id" class="list-item task-list-item">
            <div class="next-pointer">
              <hub-icon v-if="task.isNext" name="chevron-triple-right" size="md" title="My next task"></hub-icon>
            </div>
            <TaskStatusDropdown class="task-list-status" :task="task" @status-changed="$e => onStatusChange($e, task)" />
            <div class="task-list-title">
              <div :class="{ bold: task.isCriticalDate }" class="task-title" style="grid-row: 1" @click="onEditTask(task)">{{ task.title }}</div>
              <div v-if="task.milestoneRoadmapStage?.name" style="grid-row: 2" class="task-milestone-step">
                {{ `Step: ${task.milestoneRoadmapStage?.name}` }}
              </div>
            </div>
            <div class="task-list-notes">
              <div v-if="editingTaskId === task.id" class="field-wrapper">
                <div class="editor-buttons-wrapper"></div>
                <hub-editor
                  ref="input"
                  v-model="editedNotes"
                  class="hub-editor"
                  :floating-panel="true"
                  :fixed-height="false"
                  @blur="onSaveNotes(task)"
                />
              </div>
              <styled-content v-else class="notes-content" @click="onEditNotes(task)">
                <div v-html="task.notes" />
              </styled-content>
            </div>
            <div class="task-list-assignees">
              <hub-username-list :value="task.assignees || []" />
            </div>
            <div class="task-list-dueAt">
              <hub-locale-due-at :due-at="task.dueAt" :class="{ bold: task.isCriticalDate }" :emtpy-due-at-text="'no due date'" />
              <div v-if="task.durationHours" class="duration">Duration: {{ task.durationHours }}h</div>
            </div>
          </li>
        </transition-group>
      </div>
    </li>
  </transition-group>
  <div v-else class="milestones-loading">
    <hub-icon name="loading" spin size="lg" />
  </div>
</template>

<script>
import UsernameList from './../../reports/components/UsernameList.vue';
import LocaleDueAt from './../../reports/components/LocaleDueAt.vue';
import Icon from './../../common/Icon.vue';
import MetadataModal from './MetadataForm';
import MilestoneNotes from './MilestoneNotes.vue';
import StyledContent from '@/components/common/editor/StyledContent';
import Editor from '@/components/common/editor/Editor';
import ToastService from '@/plugins/ToastService';
import TaskStatusDropdown from '@/components/common/TaskStatusDropdown';

import { createApp, h } from 'vue';

import { mapState } from 'vuex';

/* eslint-disable vue/one-component-per-file */
export default {
  components: {
    'hub-editor': Editor,
    'hub-username-list': UsernameList,
    'hub-locale-due-at': LocaleDueAt,
    TaskStatusDropdown,
    'hub-icon': Icon,

    MilestoneNotes,
    StyledContent
  },
  props: {
    milestones: {
      type: Array,
      default: () => []
    }
  },
  emits: ['editTask', 'createTask', 'editMilestone', 'taskEdited'],
  data() {
    return {
      editingTaskId: null,
      editedNotes: null,
      host: location?.host
    };
  },
  computed: {
    ...mapState({
      milestoneTemplates: s => s.milestones.templates,
      templateCollection: s => s.tasks.templateCollection,
      isGetTemplateCollectionPending: s => s.tasks.isGetTemplateCollectionPending
    })
  },
  async created() {
    const uniqueIds = Array.from(new Set(this.milestones.map(m => m.workflow.id)));
    const milestoneIds = this.milestones.map(m => m.workflow.milestoneId);
    await Promise.all([
      this.$store.dispatch('milestones/bulkGetTemplates', uniqueIds),
      this.$store.dispatch('tasks/getTemplateCollection'),
      this.$store.dispatch('milestoneNotes/getNotesForMilestones', milestoneIds)
    ]);
  },
  methods: {
    toggleMilestone(milestone) {
      milestone.expanded = !milestone.expanded;
    },
    onEditTask(task) {
      this.$emit('editTask', task);
    },
    onEditNotes(task) {
      this.editingTaskId = task.id;
      this.editedNotes = task.notes;
    },
    async onSaveNotes(task) {
      this.editingTaskId = null;
      if (task.notes === this.editedNotes) {
        return;
      }

      await this.$store.dispatch('tasks/update', {
        ...task,
        assignees: task.assignees || [],
        notes: this.editedNotes
      });
      this.$emit('taskEdited', task);
    },
    onCreateTask(milestone) {
      this.$emit('createTask', milestone);
    },
    editMilestone(milestone) {
      this.$emit('editMilestone', milestone);
    },
    async onStatusChange(newStatus, task) {
      let metadata = null;
      let form;
      let formData;
      const workflowTemplate = this.milestoneTemplates[task.workflow.id];
      if (workflowTemplate.isRequestPending || workflowTemplate.isRequestFailed) {
        this.$toast.error({
          title: 'Page didn`t load correctly.',
          message: `Please, try again later or contact our development team.`
        });
      }
      if (workflowTemplate && workflowTemplate.template) {
        const milestoneTemplate = workflowTemplate.template.find(template => template.id === task.workflow.milestoneTemplateId);
        if (milestoneTemplate) {
          const path = milestoneTemplate.paths.find(path => {
            return path.from.step === task.workflow.stepId && path.from.port === newStatus;
          });
          if (path && path.from.action && path.from.action.form) {
            form = path.from.action && path.from.action.form;
            const formValues = await this.$store.dispatch('steps/getForm', {
              template: form.properties,
              model: { thisTask: task },
              context: {
                inventionId: task.invention.inventionId || task.invention.id,
                milestoneId: task.workflow.milestoneId
              }
            });
            metadata = await this.requestMetadata({ title: form.name, properties: formValues }, task);
            if (!metadata) {
              this.isRequestPending = false;
              return;
            }

            formData = {
              name: form.name,
              properties: metadata
            };
          }
        }
      }
      const item = await this.$store.dispatch('tasks/getById', task.id);

      if (item) {
        try {
          item.assignees = item.assignees?.length ? item.assignees : [];
          await this.$store.dispatch('tasks/update', {
            ...item,
            status: newStatus,
            formData: formData || {}
          });
          this.$emit('taskEdited', task);
        } catch (e) {
          const message =
            e.response?.status === 403 ? `You don't have enough permissions to commit this action.` : JSON.parse(await e.response.text())?.message;
          this.$toast.error({
            title: 'Update failed',
            message: message || `Please, try again later or contact our development team.`
          });
        }
      }
    },
    async requestMetadata(form, task) {
      const self = this;
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };
        const instance = createApp({
          render() {
            return h(MetadataModal, {
              form,
              task,
              onClose() {
                closeWithResult();
              },
              onSubmit(result) {
                closeWithResult(result);
              }
            });
          }
        });

        instance.use(self.$store);
        instance.use(ToastService);
        instance.mount(document.getElementById('modal'));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.milestones-loading {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.milestones-list {
  overflow-y: visible !important;
}
.list {
  .list-item {
    display: grid;
    grid-gap: 0;
    border-bottom: 1px solid var(--theme-highlight);
    background: var(--theme-surface);
    font-size: 0.75rem;
    min-height: 1.75rem;
    align-items: center;

    .list-button {
      cursor: pointer;
      padding: 5px;
      height: 30px;
      &:hover {
        background: var(--theme-highlight);
      }
    }

    > * {
      //box-shadow: 0 0 1px 0 #eee inset;

      height: 100%;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: pre-line;

      > label {
        overflow-x: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
      }
    }
    &.header {
      font-size: 0.75rem;
      color: var(--theme-on-background-accent);
      > * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 0.5rem;
      }
    }

    .list-item-actions {
      display: none;
    }
  }

  &:not(:last-child) {
    margin-right: 6px;
  }

  .task-list-status {
    align-items: flex-start;
  }

  .milestone-list-item {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: max-content max-content;
    background: transparent;
    min-width: 0;
    min-height: 0;
    padding: 0.5rem 0;
    border: 0;
    border-bottom: 1px solid var(--theme-highlight);

    .edit-milestone {
      display: none;
    }

    &:hover {
      .edit-milestone {
        display: block;
      }
    }

    > .list:first-child {
      margin: 0;
      padding: 0;
      display: block;
      overflow: hidden;
      .list-item {
        grid-template-columns: 0px 30px 30px 1fr auto;
        background: transparent;
        border: 0 none;

        .milestone-list-item-title {
          justify-content: space-between;
          grid-column: 4;
          font-weight: 700;
          min-width: 200px;
          text-transform: uppercase;

          .title {
            padding: 10px 10px 10px 0px;
            color: var(--theme-primary);
          }

          .buttons {
            display: flex;
          }
        }
        .milestone-list-item-dueAt {
          font-weight: 700;
          color: var(--theme-on-surface-accent);
          text-decoration: underline;
          grid-column: 6/6;
          min-width: 80px;
          justify-content: flex-start;
        }
      }
    }
  }
  .task-list {
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0;
    grid-auto-rows: min-content;

    li:nth-child(odd) {
      background: var(--theme-surface);
    }

    .vertical-alignment {
      align-self: start;
    }

    .next-pointer {
      align-items: flex-start;
      padding-right: 0;
      padding-left: 0.3rem;
      padding-top: 0.5rem;
    }

    .task-list-enter-active {
      transition: transform 0.8s ease, background-color 10s linear, opacity 2s linear;
    }

    .task-list-enter-from {
      opacity: 0;
      transform: translateX(50px);
      background-color: var(--theme-surface);
    }

    .task-list-item {
      display: grid;
      grid-template-columns: 30px 40px 35% auto max-content max-content max-content;
      background: transparent;
      border-bottom: 0;

      .task-list-title {
        min-width: 200px;
        padding: 0.7rem 0;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .task-title {
        padding-top: 0.1rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .task-milestone-step {
        font-size: 0.7rem;
        margin-top: 0.1rem;
        justify-content: flex-end;
        align-items: flex-start;
        font-style: italic;
      }

      .task-list-notes {
        justify-content: flex-start;
        align-items: center;
        font-style: italic;
        position: relative;
        font-size: 8;
        min-width: 200px;
        padding: 0;
        .editor-buttons-wrapper {
          display: flex;
          justify-content: flex-end;
        }
        .field-wrapper {
          width: 100%;

          .hub-editor {
            border: 1px solid var(--theme-highlight);
          }
        }

        .notes-content {
          border: 1px solid transparent;
          min-width: 200px;
          cursor: text;
        }
      }

      .task-list-assignees {
        margin-top: 0.25rem;
        width: 100px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .task-list-dueAt {
        display: block;
        margin-top: 0.25rem;
        align-items: flex-start;
        min-width: 80px;
        .duration {
          font-size: 0.6rem;
          font-style: italic;
        }
      }
    }
  }
}
.bold {
  font-weight: 700;
  font-size: 0.8rem;
}
.notes-section {
  border-top: 1px solid var(--theme-highlight);
  align-self: start;
}
</style>
